/* eslint-disable no-use-before-define */
import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";

import { PieChart as PieChartIcon } from "react-feather";

// import EventNoteIcon from "@material-ui/icons/EventNote";
import SettingsIcon from "@material-ui/icons/Settings";
import { ImStatsDots, ImStatsBars } from "react-icons/im";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import ContactsIcon from "@material-ui/icons/Contacts";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import SubjectIcon from "@material-ui/icons/Subject";
import EqualizerIcon from "@material-ui/icons/Equalizer";

import NavItem from "./NavItem";
const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: PieChartIcon,

        href: "/dashboard",
      },
      {
        title: "User Management",
        icon: SwapVertIcon,
        href: "/user-management",
      },
      {
        title: "Admin Management",
        icon: SwapVertIcon,
        href: "/admin-management",
      },
      {
        title: "Subscription Management",
        icon: ImStatsDots,
        href: "/subscription-management",
      },
      {
        title: "Trade Management",
        icon: ImStatsBars,
        href: "/trading-details",
      },
      {
        title: "Settings",
        icon: SettingsIcon,
        href: "/profile",
      },
      {
        title: "Static Content Management",
        icon: EqualizerIcon,
        href: "/static-management",
      },
      {
        title: " Contact Us ",
        icon: ContactsIcon,
        href: "/contact-us",
      },
      {
        title: "Social Media Management ",
        icon: SubjectIcon,
        href: "/social-management",
      },
      {
        title: " FAQ Management ",
        icon: HeadsetMicIcon,
        href: "/faq-management",
      },

      {
        title: "Partner Management",
        icon: ImStatsBars,
        href: "/partner-management",
      },
      // {
      //   title: "Help Center",
      //   icon: HeadsetMicIcon,
      //   href: "/faq",
      // },

      // {
      //   title: "Log Out",
      //   icon: PowerSettingsNewIcon,
      //   href: "/",
      //   // remove: window.localStorage.removeItem("token"),
      // },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean()}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    // background: theme.palette.primary.main,
  },
  desktopDrawer: {
    width: 200,
    top: 80,

    height: "calc(100% - 80px)",
    background: "#19194B",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      style={{ backgroundColor: "#19194b" }}
    >
      <Box height="100%" display="flex" flexDirection="column">
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          style={{ backgroundColor: "#19194b" }}
        >
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <Box py={4}>
              {sections.map((section, i) => (
                <List
                  key={`menu${i}`}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: section.items,
                    pathname: location.pathname,
                  })}
                </List>
              ))}
              {/* <List
                // key={`menu${i}`}
                // subheader={
                //   <ListSubheader disableGutters disableSticky>
                //     <PowerSettingsNewIcon />
                //   </ListSubheader>
                // }
                style={{
                  display: "flex",
                  flexDirection:"row",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                }}
              >
                <PowerSettingsNewIcon />
                Log Out
              </List> */}
            </Box>
          </PerfectScrollbar>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
