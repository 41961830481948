// const url = "http://172.16.1.176:1852";
const url = "https://node-arbitrage.mobiloitte.com";
// export const socketURL = "wss://node-arbitrage.mobiloitte.com";

// const url = "http://172.16.1.176:1852";
// const url = "https://node-arbitrage.mobiloitte.com";

const user = `${url}/api/v1/user`;
const admin = `${url}/api/v1/admin`;
const staticData = `${url}/api/v1/static`;
const faq = `${url}/api/v1/faq`;
const contactUs = `${url}/api/v1/contactUs`;

const auth = `${url}/auth`;

const ApiConfig = {
  auth: `${auth}`,
  googleTwoFAAuth: `${auth}/verify-google`,
  // viewProfile: `${admin}/viewProfile`,

  adminAnalyticsDashboard: `${admin}/adminAnalyticsDashboard`,
  listSubAdmin: `${admin}/listSubAdmin`,
  listUser: `${admin}/listUser`,
  activeBlockSubAdmin: `${admin}/activeBlockSubAdmin`,
  activeBlockUser: `${admin}/activeBlockUser`,
  viewSubAdmin: `${admin}/viewSubAdmin`,
  viewUser: `${admin}/viewUser`,
  addSubAdmin: `${admin}/addSubAdmin`,
  deleteUser: `${admin}/deleteUser`,
  creatRuleList: `${admin}/createRuleList`,
  viewCreateRule: `${admin}/viewCreateRule`,
  listSubscription: `${admin}/listSubscription`,
  viewSubscription: `${admin}/viewSubscription`,
  viewProfile: `${admin}/viewProfile`,
  editProfile: `${admin}/editProfile`,
  changePassword: `${admin}/changePassword`,
  deleteSubAdmin: `${admin}/deleteSubAdmin`,
  editSubscription: `${admin}/editSubscription`,
  addSubscription: `${admin}/addSubscription`,

  staticContentList: `${staticData}/staticContentList`,
  viewStaticContent: `${staticData}/viewStaticContent`,
  editStaticContent: `${staticData}/editStaticContent`,
  forgotPassword: `${admin}/forgotPassword`,
  otpVerify: `${admin}/otpVerify`,
  resetPassword: `${admin}/resetPassword`,
  resendOtp: `${admin}/resendOtp`,
  myaccount: `${admin}/loginAdmin`,
  tradeDetails: `${admin}/tradeDetails`,
  tradingView: `${admin}/tradeView`,
  connectedListUser: `${admin}/connectedListUser`,
  pieChart: `${admin}/pieChart`,
  listUserByMonths: `${admin}/listUserByMonths`,

  //social management
  addSocialLink: `${admin}/addSocialLink`,
  listSocialLink: `${admin}/listSocialLink`,
  editSocialLink: `${admin}/editSocialLink`,
  activeBlockSocialLink: `${admin}/activeBlockSocialLink`,

  //Partner management
  addPartner: `${admin}/addPartner`,
  listPartner: `${admin}/listPartner`,
  editPartner: `${admin}/editPartner`,
  activeBlockPartner: `${admin}/activeBlockPartner`,

  //FAQ management
  addFAQ: `${faq}/addFAQ`,
  listFAQ: `${faq}/FAQList`,
  viewFAQ: `${faq}/viewFAQ`,
  editFAQ: `${faq}/editFAQ`,

  //Contact Us
  listQuery: `${contactUs}/listQuery`,
  deleteQuery: `${contactUs}/deleteQuery/`,
  viewQuery: `${contactUs}/viewQuery`,
  replyQuery: `${contactUs}/replyQuery/`,
};

export default ApiConfig;
