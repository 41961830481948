export default {
  h1: {
    fontWeight: 500,
    fontSize: 35,
  },
  h2: {
    fontWeight: 500,
    fontSize: 30,
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
  },
  h5: {
    fontWeight: 500,
    fontSize: 18,
  },
  h6: {
    // fontWeight: 500,
    // fontSize: 16,
    position: "absolute",
    top: "-22px",
    backgroundColor: "#fff",
    padding: "5px 10px",
    left: "0px",
    fontSize: "19px",
    color: "#1690f0",
    fontWeight: 600,
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
  },
};
