import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
// import LoginLayout from "src/layouts/LoginLayout";
import HomeLayout from "./layouts/HomeLayout";
export const routes = [
  // {
  //   exact: true,
  //   path: "/",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/auth/LogIn/index")),
  // },

  // {
  //   exact: true,
  //   path: "/signup",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/auth/LogIn/SignUp")),
  // },
  {
    exact: true,
    path: "/login",
    // layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/SignIn")),
  },
  {
    exact: true,
    path: "/subscription-management",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/Subscription-Management/SubscriptionHistory")
    ),
  },
  {
    exact: true,
    path: "/about-us",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContent/AboutusPage")),
  },
  {
    exact: true,
    path: "/view-subscription",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/Subscription-Management/viewSubscription")
    ),
  },
  {
    exact: true,
    path: "/view-transaction",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/Subscription-Management/ViewTransaction")
    ),
  },
  {
    exact: true,
    path: "/trade-management",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/TradeHistory")),
  },
  {
    exact: true,
    path: "/trading-details",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/TradingHistory")),
  },
  {
    exact: true,
    path: "/view-trading",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/TradingDet")),
  },
  {
    exact: true,
    path: "/admin-management",
    guard: false,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/AdminManagement/AdminManagement")),
  },
  {
    exact: true,
    path: "/supportedCurrencies",
    layout: HomeLayout,
    component: lazy(() => import("src/views/Home/SuppotedExchanges")),
  },
  {
    exact: true,
    path: "/press",
    layout: HomeLayout,
    component: lazy(() => import("src/views/Home/Press")),
  },
  {
    exact: true,
    path: "/news",
    layout: HomeLayout,
    component: lazy(() => import("src/views/Home/News")),
  },
  {
    exact: true,
    path: "/edit-subscription",
    layout: DashboardLayout,
    component: lazy(() => import("src/component/EditSubscription")),
  },
  {
    exact: true,
    path: "/add-subscription",
    layout: DashboardLayout,
    component: lazy(() => import("src/component/AddSubscription")),
  },

  {
    exact: true,
    path: "/support",
    layout: HomeLayout,
    component: lazy(() => import("src/views/Home/Support")),
  },
  // {
  //   exact: true,
  //   path: "/about",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/Home/About")),
  // },
  // {
  //   exact: true,
  //   path: "/price",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/Price")),
  // },
  // {
  //   exact: true,
  //   path: "/dashboard/price",
  //   // layout: HomeLayout,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/Price")),
  // },
  {
    exact: true,
    path: "/faq",
    // layout: HomeLayout,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/Home/FAQ")),
  },
  {
    exact: true,
    path: "/confirmEmail",
    // layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/ConfirmEmail")),
  },
  {
    exact: true,
    path: "/newPassword",
    // layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/NewPassword")),
  },
  {
    exact: true,
    path: "/email-OTP",
    // layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/EmailCode")),
  },
  {
    exact: true,
    path: "/emailOTP",
    // layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/EmailCodeNew")),
  },

  {
    exact: true,
    path: "/",
    // layout: LoginLayout,
    component: lazy(() => import("src/views/auth/LogIn/Login")),
  },
  {
    exact: true,
    path: "/dashboard",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard")),
  },
  {
    exact: true,
    path: "/user-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/UserManagement")),
  },
  {
    exact: true,
    path: "/add-admin",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/AdminManagement/AddAdmin")),
  },
  {
    exact: true,
    path: "/getList",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Exchange/GetList")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  // {
  //   exact: true,
  //   path: "/dashboard",
  //   // guard:true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard")),
  // },
  // {
  //   exact: true,
  //   path: "/dashboard/exchange",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/Exchange/exchange")
  //   ),
  // },
  {
    exact: true,
    path: "/profile",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Profile/Profile")),
  },
  {
    exact: true,
    path: "/edit-profile",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Profile/EditProfile")
    ),
  },
  {
    exact: true,
    path: "/change-password",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Profile/ChangePassword")
    ),
  },
  {
    exact: true,
    path: "/static-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/StaticContent/StaticCmange")),
  },
  {
    exact: true,
    path: "/social-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/SocilaManagement/SocialList")
    ),
  },
  {
    exact: true,
    path: "/edit-social",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/SocilaManagement/SocilaDetails")
    ),
  },
  {
    exact: true,
    path: "/faq-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/FaqManagement/FaqList")),
  },
  {
    exact: true,
    path: "/contact-us",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContactUs/Contactus")
    ),
  },
  {
    exact: true,
    path: "/edit-faq",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/FaqManagement/FaqDetails")),
  },
  {
    exact: true,
    path: "/view-faq",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/FaqManagement/ViewFAQ")),
  },
  {
    exact: true,
    path: "/view-contact",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/ContactUs/Viewcontact")
    ),
  },
  {
    exact: true,
    path: "/partner-management",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/PartnerManagement/PartnerList")
    ),
  },
  {
    exact: true,
    path: "/edit-partner",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/PartnerManagement/PartnerDetails")
    ),
  },
  {
    exact: true,
    path: "/view-partner",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/FaqManagement/ViewFAQ")),
  },
  {
    exact: true,
    path: "/user-details",
    // guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/views/UserManagement/ViewDetails")),
  },
  // {
  //   exact: true,
  //   path: "/arbitrageDirect",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/ArbitrageDirect")),
  // },
  // {
  //   exact: true,
  //   path: "/arbitragetriangular",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/ArbitrageTriangular")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/arbitrageloop",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/ArbitrageLoop")),
  // },
  // {
  //   exact: true,
  //   path: "/arbitrageintraexchange",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/ArbitrageIntraExchange")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/newRule",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/NewRule")),
  // },
  // {
  //   exact: true,
  //   path: "/analytics",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/Analytics")),
  // },
  // {
  //   exact: true,
  //   path: "/dashboard/exchange",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/Exchange/exchange1")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/dashboard/exchange2",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/Exchange/exchange2")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/dashboard/exchange3",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Dashboard/Exchange/exchange3")
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/dashboard/stats",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/Stats/stats1")),
  // },
  // {
  //   exact: true,
  //   path: "/dashboard/chart",
  //   guard: true,
  //   layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/Charts/chart")),
  // },
  {
    exact: true,
    path: "/view-rule",
    guard: true,
    layout: DashboardLayout,
    component: lazy(() => import("src/component/ViewRuleEvent")),
  },
  // {
  //   exact: true,
  //   path: "/livetrack",
  //   // guard:true,
  //   // layout: DashboardLayout,
  //   component: lazy(() => import("src/views/pages/Dashboard/LiveTracking")),
  // },
  {
    component: () => <Redirect to="/404" />,
  },
];
