import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiFormControl: {
      root: {
        border: "0",
        marginTop: "6px !important",
        margin: "0",
        display: "inline-flex",
        padding: "0",
        position: "relative",
        minWidth: "0",
        flexDirection: "column",
        verticalAlign: "top",
      },
      MuiOutlinedInput: {
        inputMultiline: {
          fontSize: "14px !important",
        },
        root: {
          height: "35px",
        },
        input: {
          border: "1px solid grey",
          height: "35px",
          padding: "10px",
          fontSize: "14px",
          marginTop: "5px",
          borderRadius: "20px",
          position: "relative",
        },
      },
    },
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: "#ff157a",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: "0",
        marginRight: "0",
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: " #19194b",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiInput: {
      root: {
        paddingLeft: "10px",
      },
    },
    MuiButton: {
      outlined: {
        width: "200px",
      },
      contained: {
        boxShadow: "box-shadow: 0 2px 8px 0 rgb(99 99 99 / 20%);",
        // borderRadius: 27,
        "&:hover": {
          boxShadow: "none",
        },
      },
      outlined: {
        boxShadow: "none",
        borderRadius: 27,
        borderWidth: 2,
      },
      outlinedPrimary: {
        borderRadius: 27,
        boxShadow: "none",
      },
      containedSizeLarge: {
        color: "#ffffff",
        padding: "14px 50px",
      },
    },
    MuiInput: {
      underline: {
        "&::before": {
          display: "none",
        },
        "&::after": {
          display: "none",
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: " 9px",
        fontSize: "14px",
      },
    },
    MuiPaginationItem: {
      root: {
        height: "26px",
        minWidth: "26px",
      },
    },
    MuiFilledInput: {
      underline: {
        "&::before": {
          display: "none !important",
        },
        "&::after": {
          display: "none !important",
        },
      },
    },
    MuiSelect: {
      filled: {
        marginTop: "-16px",
      },
    },
    MuiInputBase: {
      root: {
        color: "#52565c",
        cursor: "text",
        marginTop: "6px !important",
        display: "inline-flex",
        position: "relative",
        fontSize: "1rem",
        boxSizing: "border-box",
        alignItems: "center",
        fontFamily: '"Roboto",  "Helvetica",  "Arial",  sans-serif',
        fontWeight: "400",
        lineHeight: "1.1876em",
        letterSpacing: "0.00938em",
      },

      inputMultiline: {
        fontSize: "14px !important",
        borderRadius: "0px !important",
        height: "52px !important",
      },
      multiline: {
        padding: "20.5px 9px !important",
        fontSize: "14px !important",
        borderRadius: "0px !important",
        height: "70px !important",
      },
      input: {
        height: "9px",
      },
    },
    MuiInputBase_root_MuiOutlinedInput_root_MuiInputBase_formControl_MuiInputBase_marginDense_MuiOutlinedInput_marginDense:
      {
        height: "45px",
      },
    MuiInputBase_inputMultiline: {
      resize: "none !important",
      fontSize: "14px !important",
    },
    // select: {
    //   outlined: {
    //     age: {
    //       native: {
    //         simple: {
    //           height: "20px !important",
    //         },
    //       },
    //     },
    //   },
    // },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f3f7f9",
      paper: colors.common.white,
    },
    primary: {
      main: "#313b48",
      dark: "#202831",
      light: "#e2e3fa",
    },
    secondary: {
      main: "#ff0610",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
