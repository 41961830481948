import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import ApiConfig, { socketURL } from "src/config/APICongig";
import { toast } from "react-toastify";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  //   const [userData] = useState({});

  const [userData, setUserDate] = useState();
  const [added_exchange, setAdded_exchange] = useState([]);
  const [popup, setPopup] = useState(false);
  const [exchangeNameList, setExchangeNameList] = useState([]);
  const [allExchangeTotal, setallExchangeTotal] = useState("");
  const [exchangeList, setExchangeList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [notificationList, setNotificationList] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const getExchangeBalanceHandler = async () => {
    await axios({
      method: "POST",
      url: ApiConfig.getExchangeBalance,
      headers: {
        token: window.localStorage.getItem("token"),
      },
    })
      .then(async (res) => {
        if (res.data.responseCode === 200) {
          setExchangeNameList(res.data.result.responseResult);
          setallExchangeTotal(res.data.result.allExchangeTotal);
          setAdded_exchange(res.data.result.responseResult);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const viewProfileHandler = async () => {
    try {
      const res = await axios.get(ApiConfig.viewProfile, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      });
      if (res.data.responseCode === 200) {
        setUserDate(res?.data?.result);
      } else {
        setUserDate();
        setIsLogin(false);
        if (res.data.responseCode === 501) {
          toast.error("Session Expired");
          window.localStorage.removeItem("token");
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    if (isLogin) {
      viewProfileHandler();
      getExchangeBalanceHandler();
      exchangeListHandler();
    } else {
      setIsLoading(false);
    }
  }, [isLogin]);

  const exchangeListHandler = async () => {
    await axios
      .get(ApiConfig.exchangeList, {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      })
      .then(async (res) => {
        if (res.data.responseCode === 200) {
          setExchangeList(res.data.result.docs);
        } else {
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };
  // useEffect(() => {
  //   const web = new WebSocket(socketURL);

  //   const accessToken = localStorage.getItem("token");
  //   if (accessToken) {
  //     try {
  //       web.onopen = () => {
  //         const dataToSend = {
  //           token: accessToken,
  //         };
  //         web.send(JSON.stringify(dataToSend));
  //         web.onmessage = async (event) => {
  //           if (event.data !== "[object Promise]" && event.data !== "null") {
  //             let obj = JSON.parse(event.data);
  //             console.log("obj", obj);
  //             if (obj.data && obj.data.length > 0) {
  //               setNotificationList(obj.data);
  //               setUnreadCount(obj.unReadCount);
  //             } else {
  //               setNotificationList([]);
  //               setUnreadCount(0);
  //             }
  //           }
  //         };
  //       };
  //       return () => {
  //         setNotificationList();
  //         setUnreadCount(0);
  //         web.close();
  //       };
  //     } catch (err) {}
  //   }
  // }, [isLogin]);

  let data = {
    added_exchange,
    popup,
    exchangeList,
    isLoading,
    exchangeNameList,
    allExchangeTotal,
    userLoggedIn: isLogin,
    userData,
    notificationList,
    unreadCount,
    viewProfileHandler: () => viewProfileHandler(),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
    exchange: (data) => {
      setAdded_exchange(data);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
