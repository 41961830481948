import React from "react";
import PropTypes from "prop-types";
import Footer from "../LoginLayout/Footer";
import TopBar from "../LoginLayout/TopBar";
const HomeLayout = ({ children }) => {
  return (
    <>
      <TopBar />
      <div>{children}</div>
      <Footer />
    </>
  );
};

HomeLayout.propTypes = {
  children: PropTypes.node,
};

export default HomeLayout;
